/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,700;1,200;1,300&display=swap');
.alert-message.sc-ion-alert-md {
    font-size: 14px;
}

.error-message {
    color: red;
}

.my-custom-class {
    --background: rgb(255, 255, 255);
    --width: 170px;
}

.alert-radio-label.sc-ion-alert-md {
    padding-left: 52px;
    padding-right: 26px;
    padding-top: 13px;
    padding-bottom: 13px;
    flex: 1;
    color: var(--ion-color-step-850, #262626);
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.alert-checkbox-label.sc-ion-alert-md {
    padding-left: 52px;
    padding-right: 26px;
    padding-top: 13px;
    padding-bottom: 13px;
    flex: 1;
    color: var(--ion-color-step-850, #262626);
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: pre-line;
}

ion-title,
ion-content,
ion-input,
ion-select,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
}